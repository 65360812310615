import React, { PropsWithChildren, useMemo } from 'react'

import { LoadingSpinner } from 'src/components/atoms/LoadingSpinner'
import { CtaBandSection } from 'src/components/molecules/CtaBandSection'
import { FeatureCarousel } from 'src/components/molecules/FeatureCarousel'
import { ImageDetailTileSection } from 'src/components/molecules/ImageDetailTileSection'
import { ImageListSection } from 'src/components/molecules/ImageListSection'
import { ImageTextSection } from 'src/components/molecules/ImageTextSection'
import { TilesSection } from 'src/components/molecules/TilesSection'
import { Content } from 'src/components/molecules/sanity/Content'
import { ActionBlock } from 'src/components/molecules/sanity/CtaBlock'
import { BusinessCarbonCalculatorSection } from 'src/components/organisms/Business/BusinessCarbonCalculatorSection'
import { BusinessFooterSection } from 'src/components/organisms/Business/BusinessFooterSection'
import { ImageTilesSection } from 'src/components/organisms/ImageTilesSection'

import { NotFound } from 'src/pages/404/404'
import { ArrayElement } from 'src/types/common'
import { getSanityImageUrl } from 'src/utils/sanity'
import { trpc } from 'src/utils/trpc'

import { Page, PageProps } from '..'
import { FullWidthContainer } from '../../Container'
import { SectionSpacer } from './SectionSpacer'
import { StandaloneHeading } from './StandaloneHeading'
import { SanityContentPageResult } from './types'

type SanityContentPageProps = Omit<PageProps, 'children'> &
  PropsWithChildren<{
    slug: string
  }>

type SectionContentData = ArrayElement<
  NonNullable<SanityContentPageResult['content']>
>

const getContentSection = (data: SectionContentData): JSX.Element | null => {
  switch (data._type) {
    case 'content':
      return <Content {...data} />
    case 'imageTiles':
      return <ImageTilesSection {...data} />
    case 'spacer':
      return <SectionSpacer {...data} />
    case 'ctaBand':
      return <CtaBandSection {...data} />
    case 'tiles':
      return <TilesSection {...data} />
    case 'heading':
      return <StandaloneHeading {...data} />
    case 'imageList':
      return <ImageListSection {...data} />
    case 'imageText':
      return <ImageTextSection {...data} />
    case 'imageDetailSection':
      return <ImageDetailTileSection {...data} />
    case 'carbonCalculator':
      return <BusinessCarbonCalculatorSection {...data} />
    case 'ctaBlock':
      return <ActionBlock {...data} />
    case 'featureCarousel':
      return <FeatureCarousel {...data} />
    default:
      return null
  }
}

const renderContentSection = (
  sectionData: SectionContentData,
  index: number
) => {
  const component = getContentSection(sectionData)

  if (!component) return null

  return (
    <FullWidthContainer key={`container_${index}`} className='!py-0'>
      {component}
    </FullWidthContainer>
  )
}

const renderHeroSection = (pageData: SanityContentPageResult) => {
  const heroData = pageData.hero
  if (heroData) {
    return (
      <FullWidthContainer className='!py-0 mt-[50px] md:mt-[100px]'>
        <ImageTextSection {...heroData} noPadding isHero />
      </FullWidthContainer>
    )
  }
}

const renderFooterSection = (pageData: SanityContentPageResult) => {
  const footerData = pageData.footer
  if (footerData) {
    return <BusinessFooterSection {...footerData} />
  }
}

const SanityContentPage = ({ slug, ...pageProps }: SanityContentPageProps) => {
  const { data, isLoading } = trpc.useQuery([
    'sanity.getContentPageBySlug',
    { slug }
  ])

  const hero = useMemo(() => {
    if (!data) return null
    return renderHeroSection(data)
  }, [data])

  const footer = useMemo(() => {
    if (!data) return null
    return renderFooterSection(data)
  }, [data])

  if (!isLoading && !data) return <NotFound />

  return (
    <Page
      isFullWidth
      title={data?.metaTitle || data?.title}
      metaTitle={data?.metaTitle || data?.title}
      metaDescription={data?.metaDescription || data?.description}
      image={data?.openGraphImage && getSanityImageUrl(data.openGraphImage)}
      showHero={false}
      {...pageProps}
    >
      <LoadingSpinner isLoading={isLoading}>
        <div className='flex flex-col mx-auto'>
          {hero}
          {data?.content.map(renderContentSection)}
          <div id='contact'>{footer}</div>
        </div>
      </LoadingSpinner>
    </Page>
  )
}

export default SanityContentPage
