const Slider = (
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
) => {
  return (
    <div className='w-full'>
      <input {...props} className='slider' type='range' />
    </div>
  )
}

export default Slider
