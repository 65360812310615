import { Formik } from 'formik'
import Image from 'next/image'
import numeral from 'numeral'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { Button } from 'src/components/atoms/Button'
import { Divider } from 'src/components/atoms/Divider'
import { ExternalLink, InternalLink } from 'src/components/atoms/Link'
import { Slider } from 'src/components/atoms/Slider'
import { SwitchOption, TabSwitch } from 'src/components/atoms/TabSwitch'
import {
  Heading2,
  Heading3,
  Heading4,
  ParagraphNoSpace
} from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'

import { carbonCalculatorPath } from 'src/config/paths'
import { FormFieldTypes } from 'src/types/form'

import { calculateCarbon } from '../../CarbonCalculator/helper'
import { CalculatorTab } from '../../CarbonCalculator/types'

type SwitchOptionValue = 'dollarAmount' | 'co2Emitted' | 'numberOfTrees'

type CarbonActivities = 'flights' | 'longFlight' | 'electricity' | 'car'

type CarbonActivityParams = {
  image: string
  factor: number
  textLiteralFn: (val: string) => string
}

const activityPropsMapping: {
  [key in CarbonActivities]: CarbonActivityParams
} = {
  flights: {
    image: '/img/icons/scenario-flights.svg',
    textLiteralFn: val => `${val} return flights from Wellington to Auckland`,
    factor: 5.5 // 0.18kg/km co2: 737-400, 780km/h, 90kg CO2 per passanger per hour, wlg to akl is 1hr - https://www.carbonindependent.org/22.html#:~:text=CO2%20emissions%20from%20aviation%20fuel,CO2%20per%20passenger%20per%20hour.
  },
  longFlight: {
    image: '/img/icons/scenario-international-flight.svg',
    textLiteralFn: val => `${val} return flights from Auckland to Sydney`,
    factor: 1.5
  },
  electricity: {
    image: '/img/icons/scenario-utilities.svg',
    textLiteralFn: val =>
      `Electricity use for ${val} houses on a avarage consumption of 7000kWh per year`,
    factor: 1.8 // 78g CO2e p/kWh = 0.546t for 1 household per year at 7000kWh/year - https://ecotricity.co.nz/nz-electricity-emissions-are-dropping#:~:text=In%20November%202021%20the%20figure,which%20is%20an%20amazing%20result!
  },
  car: {
    image: '/img/icons/scenario-driving.svg',
    textLiteralFn: val =>
      `Use of a medium-sized car for ${numeral(val).format('0,0')}km`,
    factor: 5847.9 // 171g CO2e per km - https://www.beehive.govt.nz/sites/default/files/2021-01/Clean%20Car%20Import%20Standard%20Explainer_0.pdf
  }
}

const CarbonActivity = ({
  activityType,
  value
}: {
  value: number
  activityType: CarbonActivities
}) => {
  const { image, textLiteralFn, factor } = activityPropsMapping[activityType]
  return (
    <div className='min-w-[260px] lg:min-w-min p-5 flex-1 flex flex-col bg-aquaSpring rounded-[10px]'>
      <Image alt={activityType} src={image} width={80} height={70} />
      <span className='text-[14px] leading-[20px] font-medium max-w-full text-wrap'>
        {textLiteralFn(numeral(value * factor).format('0,0'))}
      </span>
    </div>
  )
}

type CalculateComponentProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  onChange: (total: number) => void
}

const CalculateFromDollarAmount = ({ onChange }: CalculateComponentProps) => {
  const [amount, setAmount] = useState(50000)

  const carbonResult = useMemo(() => {
    const numberOfTrees = amount / 10
    const res = calculateCarbon({
      formTab: CalculatorTab.Stems,
      stems: numberOfTrees,
      emissions: 0,
      age: 50,
      trees: 25,
      shrubs: 75,
      includeOwnMeasurements: false,
      standTreeAge: 0,
      standTreeSurvival: 0,
      standTreeHeight: 0,
      standTreeDBH: 0,
      standShrubSurvival: 0,
      standShrubHeight: 0,
      standShrubRCD: 0
    })
    return res
  }, [amount])

  useEffect(() => {
    onChange(carbonResult.co2Total)
  }, [carbonResult, onChange])

  const handleSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
    const amnt = Number(e.target.value) || 0
    setAmount(amnt)
  }

  return (
    <div className='flex flex-col md:flex-row'>
      <div className='flex-1 flex flex-col px-[15px] py-5 md:p-10'>
        <span className='text-center text-[45px] leading-[30px] tracking-tighter md:text-[54px] md:leading-[60px] font-bold md:tracking-tight'>
          {numeral(amount).format('$0,0')}
        </span>
        <span className='mt-[10px] mb-5 text-[14px] text-center leading-[30px] font-medium'>
          At $10.00 NZD per tree.
        </span>
        <Slider
          value={amount}
          onChange={handleSliderChange}
          min={10}
          max={100000}
          step={10}
        />
      </div>
      <div className='flex flex-col gap-5 md:gap-10 flex-1 p-[15px] md:p-10 bg-aquaSpring rounded-[20px]'>
        <div className='flex flex-col'>
          <span className='text-[30px] leading-[30px] tracking-tighter md:text-[54px] md:leading-[60px] font-bold md:tracking-tight text-mostlyGreen'>
            {numeral(amount / 10).format('0,0')}
          </span>
          <span className='text-[14px] md:text-base font-medium'>
            {numeral(amount).format('$0,0')} NZD will plant {amount / 10} native
            trees.
          </span>
        </div>
        <div className='flex flex-col'>
          <span className='text-[30px] leading-[30px] tracking-tighter md:text-[54px] md:leading-[60px] font-bold md:tracking-tight text-mostlyGreen'>
            {numeral(carbonResult.co2Total).format('0,0.0')}{' '}
            <span className='text-mostlyGreen text-[20px] leading-[20px]'>
              tCO2e
            </span>
          </span>
          <span className='text-[14px] md:text-base font-medium'>
            {amount / 10} native trees will help to remove{' '}
            {numeral(carbonResult.co2Total).format('0,0.0')} tCO2 equivalent
            over 50 years.{' '}
            <InternalLink
              className='!font-medium'
              color='black-underlined'
              openInNewTab
              href={carbonCalculatorPath}
            >
              Learn more
            </InternalLink>
          </span>
        </div>
      </div>
    </div>
  )
}

const CalculateFromCo2Emitted = ({ onChange }: CalculateComponentProps) => {
  const [value, setValue] = useState(100)

  useEffect(() => {
    onChange(value)
  }, [onChange, value])

  const numberOfTrees = useMemo(() => {
    if (value === 0) return 0
    const res = calculateCarbon({
      formTab: CalculatorTab.Carbon,
      stems: 0,
      emissions: value,
      age: 50,
      trees: 25,
      shrubs: 75,
      includeOwnMeasurements: false,
      standTreeAge: 0,
      standTreeSurvival: 0,
      standTreeHeight: 0,
      standTreeDBH: 0,
      standShrubSurvival: 0,
      standShrubHeight: 0,
      standShrubRCD: 0
    })
    return Math.ceil(res.stems)
  }, [value])

  return (
    <div className='flex flex-col md:flex-row'>
      <div className='flex-1 flex flex-col gap-5 px-[15px] py-5 md:p-10'>
        <Formik
          initialValues={{
            value: undefined
          }}
          onSubmit={values => {
            setValue(values.value || 100)
          }}
        >
          {({ submitForm }) => (
            <>
              <Heading3 className='!m-0 text-[24px] leading-[30px] font-semibold'>
                Know your CO2 emissions?
              </Heading3>
              <FormField
                className='mb-0'
                placeholder='100'
                labelClassName='text-anotherGrey3'
                label='Enter tCO2 equivalent'
                id='value'
                type={FormFieldTypes.number}
                min={0}
              />
              <Button className='place-self-start' onClick={submitForm}>
                How many trees?
              </Button>
            </>
          )}
        </Formik>
      </div>
      <div className='flex flex-col gap-5 md:gap-10 flex-1 p-[15px] md:p-10 bg-aquaSpring rounded-[20px]'>
        <div className='flex flex-col'>
          <span className='text-[30px] leading-[30px] tracking-tighter md:text-[54px] md:leading-[60px] font-bold md:tracking-tight text-mostlyGreen'>
            {numeral(numberOfTrees).format('0,0')}
          </span>
          <span className='text-[14px] md:text-base font-medium'>
            Native trees will help to remove {value} tCO2 equivalent over 50
            years.{' '}
            <InternalLink
              className='!font-medium'
              color='black-underlined'
              openInNewTab
              href={carbonCalculatorPath}
            >
              Learn more
            </InternalLink>
          </span>
        </div>
        <div className='flex flex-col'>
          <span className='text-[30px] leading-[30px] tracking-tighter md:text-[54px] md:leading-[60px] font-bold md:tracking-tight text-mostlyGreen'>
            {numeral(numberOfTrees * 10).format('$0,0')}
          </span>
          <span className='text-[14px] md:text-base font-medium'>
            Cost of native trees at $10 NZD per tree.
          </span>
        </div>
      </div>
    </div>
  )
}

const CalculateFromNumberOfTrees = ({ onChange }: CalculateComponentProps) => {
  const [numberOfTrees, setNumberOfTrees] = useState(5000)

  const carbonResult = useMemo(() => {
    const res = calculateCarbon({
      formTab: CalculatorTab.Stems,
      stems: numberOfTrees,
      emissions: 0,
      age: 50,
      trees: 25,
      shrubs: 75,
      includeOwnMeasurements: false,
      standTreeAge: 0,
      standTreeSurvival: 0,
      standTreeHeight: 0,
      standTreeDBH: 0,
      standShrubSurvival: 0,
      standShrubHeight: 0,
      standShrubRCD: 0
    })
    return res
  }, [numberOfTrees])

  useEffect(() => {
    onChange(carbonResult.co2Total)
  }, [carbonResult, onChange])

  const handleSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
    const amnt = Number(e.target.value) || 0
    setNumberOfTrees(amnt)
  }

  return (
    <div className='flex flex-col md:flex-row'>
      <div className='flex-1 flex flex-col px-[15px] py-5 md:p-10'>
        <span className='text-center text-[45px] leading-[30px] tracking-tighter md:text-[54px] md:leading-[60px] font-bold md:tracking-tight'>
          {numeral(numberOfTrees).format('0,0')}
        </span>
        <span className='mt-[10px] mb-5 text-center text-[14px] leading-[30px] font-medium'>
          Native trees
        </span>
        <Slider
          value={numberOfTrees}
          onChange={handleSliderChange}
          min={1}
          max={10000}
          step={1}
        />
      </div>
      <div className='flex flex-col gap-5 md:gap-10 flex-1 p-[15px] md:p-10 bg-aquaSpring rounded-[20px]'>
        <div className='flex flex-col'>
          <span className='text-[30px] leading-[30px] tracking-tighter md:text-[54px] md:leading-[60px] font-bold md:tracking-tight text-mostlyGreen'>
            {numeral(carbonResult.co2Total).format('0,0.0')}{' '}
            <span className='text-mostlyGreen text-[20px] leading-[20px]'>
              tCO2e
            </span>
          </span>
          <span className='text-[14px] md:text-base font-medium'>
            {numberOfTrees} native trees will help to remove{' '}
            {numeral(carbonResult.co2Total).format('0,0.0')} tCO2 equivalent
            over 50 years.{' '}
            <InternalLink
              className='!font-medium'
              color='black-underlined'
              openInNewTab
              href={carbonCalculatorPath}
            >
              Learn more
            </InternalLink>
          </span>
        </div>
        <div className='flex flex-col'>
          <span className='text-[30px] leading-[30px] tracking-tighter md:text-[54px] md:leading-[60px] font-bold md:tracking-tight text-mostlyGreen'>
            {numeral(numberOfTrees * 10).format('$0,0')}
          </span>
          <span className='text-[14px] md:text-base font-medium'>
            Cost of native trees at $10 NZD per tree.
          </span>
        </div>
      </div>
    </div>
  )
}

type BusinessCarbonCalculatorSectionProps = {
  title: string
  subTitle?: string
}

const BusinessCarbonCalculatorSection = ({
  title,
  subTitle
}: BusinessCarbonCalculatorSectionProps) => {
  const [carbonTotal, setCarbonTotal] = useState<number>(0)
  const [value, setValue] = useState<SwitchOptionValue>('co2Emitted')

  const options: SwitchOption<SwitchOptionValue>[] = [
    {
      label: 'CO2',
      value: 'co2Emitted'
    },
    {
      label: 'Trees',
      value: 'numberOfTrees'
    },
    {
      label: 'Dollar',
      value: 'dollarAmount'
    }
  ]

  const calculationComponent = useMemo(() => {
    switch (value) {
      case 'dollarAmount':
        return CalculateFromDollarAmount
      case 'co2Emitted':
        return CalculateFromCo2Emitted
      case 'numberOfTrees':
        return CalculateFromNumberOfTrees
      default:
        return null
    }
  }, [value])

  const CalculationComponent = useCallback(
    ({ onChange }: CalculateComponentProps) => {
      const Cmp = calculationComponent!

      return <Cmp onChange={onChange} />
    },
    [calculationComponent]
  )

  return (
    <div className='bg-white flex flex-col px-[15px] md:px-[60px] gap-5 md:gap-10 items-stretch'>
      <div className='flex flex-col gap-[10px] items-center'>
        <Heading2 className='!m-0 text-center'>{title}</Heading2>
        {subTitle ? (
          <ParagraphNoSpace className='!text-[16px] md:!text-[20px] leading-[24px] md:leading-[30px] font-medium text-center'>
            {subTitle}
          </ParagraphNoSpace>
        ) : null}
      </div>
      <div className='w-full sm:w-[450px] place-self-center'>
        <TabSwitch
          id='carbonSwitch'
          color='dark'
          size='small'
          value={value}
          onChange={e => setValue(e.target.value)}
          options={options}
        />
      </div>
      <div className='w-full'>
        <CalculationComponent onChange={total => setCarbonTotal(total)} />
      </div>

      <Divider className='!m-0' />
      <div className='flex flex-col gap-5'>
        <Heading4 className='!m-0 !text-[16px] md:!text-[20px] !leading-[25px] font-bold'>
          Common activities that emit equivalent CO2 by New Zealand standards.
        </Heading4>
        <div className='flex flex-row whitespace-nowrap overflow-x-scroll gap-5 content-between'>
          {Object.keys(activityPropsMapping).map(a => (
            <CarbonActivity
              key={a}
              activityType={a as CarbonActivities}
              value={carbonTotal}
            />
          ))}
        </div>
        <ExternalLink
          className='!font-medium !text-[14px] self-start'
          color='black-underlined'
          href='https://help.treesthatcount.co.nz/en/articles/9266551-how-we-calculator-carbon-sequestered'
        >
          Learn how we estimate these numbers
        </ExternalLink>
      </div>
    </div>
  )
}

export default BusinessCarbonCalculatorSection
