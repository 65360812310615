import { SanityImageSource } from '@sanity/image-url/lib/types/types'
import { useMemo } from 'react'

import { Image } from 'src/components/atoms/Image'
import { ButtonLink } from 'src/components/atoms/Link'
import {
  Heading1,
  Heading2,
  Heading3,
  Paragraph
} from 'src/components/atoms/Typography'
import { ImageTextResult } from 'src/components/layouts/Page/SanityContentPage/types'
import { Text, TextValue } from 'src/components/molecules/sanity/Content'

import { getSanityImageUrl } from 'src/utils/sanity'

export type ImageTextSectionProps = Omit<
  ImageTextResult,
  'description' | 'image' | '_type'
> & {
  description?: TextValue | string
  image?: SanityImageSource | string
  noPadding?: boolean
  isHero?: boolean
}

const ImageTextSection = ({
  heading,
  headingSize = 'h2',
  image,
  ctas,
  description,
  direction = 'contentFirst',
  location,
  noPadding = false,
  isHero = false
}: ImageTextSectionProps) => {
  const isCMSImage = image && typeof image !== 'string'
  const src = isCMSImage ? getSanityImageUrl(image) : image

  const directionClasses = useMemo(() => {
    switch (direction) {
      case 'contentFirst':
        return 'lg:flex-row-reverse lg:gap-20'
      case 'contentFirstVertical':
        return 'lg:flex-col-reverse items-center'
      case 'imageFirst':
      default:
        return 'lg:flex-row lg:gap-20'
    }
  }, [direction])

  const headingComponent = useMemo(() => {
    switch (headingSize) {
      case 'h1':
        return (
          <Heading1
            className={`${
              direction === 'contentFirstVertical' ? 'text-center' : 'text-left'
            } !m-0 md:!text-[50px] md:!leading-[60px] !text-[30px] leading-[40px] whitespace-pre-line`}
          >
            {heading}
          </Heading1>
        )
      case 'h3':
        return (
          <Heading3
            className={`${
              direction === 'contentFirstVertical' ? 'text-center' : 'text-left'
            } !m-0 !text-[24px] !leading-[30px] md:!text-[28px] md:!leading-[39px] font-semibold whitespace-pre-line`}
          >
            {heading}
          </Heading3>
        )
      case 'h2':
      default:
        return (
          <Heading2
            className={`${
              direction === 'contentFirstVertical' ? 'text-center' : 'text-left'
            } !m-0 !font-bold whitespace-pre-line`}
          >
            {heading}
          </Heading2>
        )
    }
  }, [headingSize, direction, heading])

  return (
    <div
      className={`flex flex-col gap-[10px] md:gap-5 ${directionClasses} relative`}
    >
      <div
        className={`w-full rounded-[5px] ${
          direction === 'contentFirstVertical'
            ? 'aspect-square md:h-[500px]'
            : 'aspect-square lg:flex-1'
        }  relative overflow-hidden`}
      >
        {src ? <Image alt='' src={src} fill className='object-cover' /> : null}
        {location ? (
          <span
            className={`text-center absolute mx-auto w-auto md:w-fit left-5 right-5 ${
              isHero ? 'bottom-28' : 'bottom-10'
            } lg:bottom-10 text-[14px] leading-[20px] font-semibold bg-white opacity-80 px-[10px] py-2 rounded-[5px]`}
          >
            <Image
              className='inline mr-2'
              alt='pin'
              width={17}
              height={21}
              src='/img/icons/treeLocationPin.svg'
            />{' '}
            {location}
          </span>
        ) : null}
      </div>
      <div
        className={`flex flex-col gap-5 ${
          direction === 'contentFirstVertical'
            ? 'items-center pt-5 lg:!pt-0 '
            : 'justify-center'
        } lg:shadow-none lg:flex-1 bg-white lg:bg-transparent ${
          isHero ? 'mx-5 -mt-[100px] shadow-md z-10' : ''
        } lg:mt-0 lg:mx-0 ${noPadding ? 'px-5 lg:px-0' : 'px-5'} ${
          isHero ? '' : '!px-0'
        } py-5 lg:py-10 max-w-[920px]`}
      >
        {headingComponent}
        {
          // eslint-disable-next-line no-nested-ternary
          description ? (
            <span className='!text-[16px] md:!text-[20px] font-medium w-full leading-[24px] md:leading-[30px] whitespace-pre-line'>
              {typeof description === 'string' ? (
                description
              ) : (
                <Text
                  // specify the block component to override text size for portable text component
                  components={{
                    block: ({ children }) => (
                      <Paragraph
                        className={`only-of-type:!m-0 ${
                          direction === 'contentFirstVertical'
                            ? 'text-center'
                            : ''
                        } !text-[16px] md:!text-[20px] font-medium w-full leading-[24px] md:leading-[30px]`}
                      >
                        {children}
                      </Paragraph>
                    )
                  }}
                  value={description}
                />
              )}
            </span>
          ) : null
        }
        {ctas && Array.isArray(ctas) ? (
          <div
            className={`flex self-stretch ${
              direction === 'contentFirstVertical'
                ? 'md:self-center'
                : 'md:self-start'
            } md:items-start flex-col space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0`}
          >
            {ctas?.map((ctaButton, idx) => {
              const isExternal = ctaButton.externalUrl
              return (
                <ButtonLink
                  href={
                    isExternal
                      ? ctaButton.externalUrl!
                      : ctaButton.internalLink!.path
                  }
                  key={ctaButton.text}
                  color='primary'
                  variant={idx === 0 ? 'contained' : 'outlined'}
                  size='large'
                >
                  {ctaButton.text}
                </ButtonLink>
              )
            })}
          </div>
        ) : (
          ctas
        )}
      </div>
    </div>
  )
}

export default ImageTextSection
